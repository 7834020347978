import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Craig = () => {
  return (
    <Layout>
      <Head title="Craig David" />
      <h3>Craig David</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Southampton
      </h4>

      <p>
        <OutboundLink href="https://www.craigdavid.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://instagram.com/craigdavid/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        Craig Ashley David MBE (born 5 May 1981) is a British singer and
        songwriter who rose to fame in 1999, featuring on the single "Re-Rewind"
        by Artful Dodger. David's debut studio album, Born to Do It, was
        released in 2000, after which he has released a further five studio
        albums and worked with a variety of artists such as Sting, Tinchy
        Stryder, Big Narstie, Kano and Jay Sean. David has 20 UK Top 40 singles,
        and seven UK Top 40 albums, selling over 15,000,000 records worldwide as
        a solo artist. David has been nominated for fourteen Brit Awards: five
        times for Best British Male, and twice receiving a Grammy Award
        nomination for Best Male Pop Vocal Performance.
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dG1F89DetCM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-AVQWZt9hIc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/nOEgqKNrasE?si=ZJDML7c--PGGNscu"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/u5mEszYqjms?si=lz1SrZ0KlwR8Psux"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8TCt_lUmmlM?si=ButgR_kNrU7ge9Ng"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Craig;
